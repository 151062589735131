<template>
  <div>
    <template v-if="!isMobile">
      <div class="box" v-if="languageSelect==='zh'">
        <div class="ViewTitle">系统消息</div>
        <div class="messageList">
          <div class="list" v-if="messageList.length">
            <div class="item" v-for="(item, index) in messageList" :key="index">
              <div class="icon">
                <img src="@/assets/messageIcon.png" alt="" />
              </div>
              <div class="text">
                <div class="title" @click="toDetail(item)">
                  <el-badge v-if="item.status == 0" is-dot class="step" />
                  {{ item.title }}
                </div>
                <div class="flex">
                  <div class="time">{{ item.createTime }}</div>
                  <span @click="toDetail(item)"> 查看详情>></span>
                </div>
              </div>
            </div>
            <el-pagination
                hide-on-single-page
                background
                layout="prev, pager, next"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="page.pageNum"
                :page-size="page.pageSize"
                :total="page.total"
            >
            </el-pagination>
          </div>
          <div class="noData" v-else>
            <img src="@/assets/none.png" alt="" />
            暂无数据
          </div>
        </div>
      </div>
      <div class="box" v-if="languageSelect==='en'">
        <div class="ViewTitle">System message</div>
        <div class="messageList">
          <div class="list" v-if="messageList.length">
            <div class="item" v-for="(item, index) in messageList" :key="index">
              <div class="icon">
                <img src="@/assets/messageIcon.png" alt="" />
              </div>
              <div class="text">
                <div class="title" @click="toDetail(item)">
                  <el-badge v-if="item.status == 0" is-dot class="step" />
                  {{ item.title }}
                </div>
                <div class="flex">
                  <div class="time">{{ item.createTime }}</div>
                  <span @click="toDetail(item)"> 查看详情>></span>
                </div>
              </div>
            </div>
            <el-pagination
                hide-on-single-page
                background
                layout="prev, pager, next"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="page.pageNum"
                :page-size="page.pageSize"
                :total="page.total"
            >
            </el-pagination>
          </div>
          <div class="noData" v-else>
            <img src="@/assets/none.png" alt="" />
          No data available
          </div>
        </div>
      </div>
    </template>
    <div v-else class="m-system-page">
      <Header :title="$t('user.message')"></Header>
      <div class="m-orders-list" v-if="messageList.length">
        <div class="m-order-item" v-for="item in messageList" :key="item.id">
          <div class="m-order-top">
            <h3 class="threeLine">{{ item.title }}</h3>
          </div>
          <div class="m-order-bott">{{ item.createTime }}</div>
        </div>
      </div>
      <Pagination v-if="messageList.length" :size="page.pageSize" @current-change="handleCurrentChange" :total="page.total"></Pagination>
      <div class="noData" v-else>
        <img src="@/assets/none.png" alt="" />
        {{ $t('common.noData') }}
      </div>
      <Bottom></Bottom>
    </div>
  </div>

</template>
<script>
import { noticeList, noticeUpStatus } from "@/api/user";
import { shareUrl } from "@/utils/vxshare.js";
import Bottom from "@/components/mobile/Bottom.vue";
import Header from "@/components/mobile/Header.vue";
import Pagination from "@/components/mobile/Pagination.vue";

export default {
  name: "Message",
  components: {
    Bottom,
    Header,
    Pagination
  },
  data() {
    return {
      messageList: [],
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      languageSelect:'',
      isMobile: false,
    };
  },
  computed: {},
  created() {
    if (localStorage.getItem('language')) {
      this.languageSelect = localStorage.getItem('language')
    } else {
      this.languageSelect = 'zh'
    }
    this.noticeList();
    let url = window.location.host
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url:url,
        title:'油气知识',
        text:'洞悉油气行业变化，体验AI大模型，就来油气知识！',
      }
      shareUrl(shareData);
    }, 1000);
  },
  mounted() {
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
  },
  methods: {
    noticeList() {
      let userInfo = this.$store.state.user;
      let data = {
        userId: userInfo.userId,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      noticeList(data).then((res) => {
        console.log(res, "111");
        this.page.total = res.total;
        this.messageList = res.rows;
      });
    },
    toDetail: function (item) {
      if (item.status == 0) {
        noticeUpStatus(item.id).then(() => {
          this.noticeCountCom();
        });
      }
      this.$router.push({
        path: "/messageDetail",
        query: { id: item.noticeId },
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page.pageNum = val;
      this.noticeList();
    },
  },
};
</script>
<style scoped lang="scss">
.box {
  background: #fff;
  .ViewTitle {
    height: 67px;
    line-height: 67px;
    border-bottom: 1px solid #ebebeb;
    font-size: 20px;
    color: #333333;
    font-weight: 400;
    padding: 0 30px;
  }

  .messageList {
    padding-bottom: 48px;
    .list {
      padding: 0 30px;
      .item {
        display: flex;
        align-items: center;
        padding: 17px 22px;
        border: 1px solid #ebebeb;
        margin-top: 16px;
        .icon {
          width: 48px;
          height: 48px;
          margin-right: 12px;
        }
        .text {
          flex:1;
          .title {
            font-size: 14px;
            color: #000;
            font-weight: 500;
            cursor: pointer;
            max-width: 100%;
            width: fit-content;
            .step {
              .el-badge__content.is-dot {
                background-color: var(--main-color) !important;
              }
            }
          }
          .flex {
            display: flex;
            font-size: 12px;
            align-items: center;
            margin-top: 6px;
            .time {
              color: #999999;
              margin-right: 11px;
            }
            span {
              color: var(--main-color);
              font-weight: 500;
              cursor: pointer;
            }
          }
        }
      }

      .el-pagination {
        text-align: center;
        margin-top: 30px;
      }
    }
  }
}
.m-system-page{
  background-color: #f8f8f8;
  .m-orders-list{
    min-height: 74vh;
    margin: 0 20px;
    .m-order-item{
      margin-bottom: 10px;
      background-color: #fff;
      border-radius: 4px;
      overflow: hidden;
      padding: 10px;
      .m-order-top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        h3{
          font-size: 14px;
          font-weight: normal;
          word-wrap: break-word;
        }
        span{
          font-size: 12px;
        }
      }
      .m-order-bott{
        font-size: 12px;
        color: #999;
      }
    }
  }
  .noData{
    min-height: 74vh;
  }
}
</style>

